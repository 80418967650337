<template>
  <div class="w-full pt-5 px-2">
    <template>
      <div class="flex w-full mx-2">
        <h1 class="text-xl text-left font-extrabold mr-2">
          Performance Moderation
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template>
      <div class="w-full px-2 my-5">
        <Card class="p-5 pt-0">
          <CardFooter reloadcard showcalendar />
        </Card>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="h-full" v-if="isLoading">
        <Loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template v-if="items.length">
        <div class="w-full px-2">
          <Table
            :headers="headers"
            :items="items"
            v-if="items.length > 0"
            aria-label="advance Table"
            class="w-full px-0"
            :has-checkbox="false"
            :has-number="false"
            :loading="false"
            :pagination-list="paginationList"
            :page-sync="true"
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.year"
                class="font-semibold text-sm leading-5 tracking-widest"
              >
                <span class="text-darkPurple">
                  {{ item.data.year }}
                </span>
              </div>
              <div v-else-if="item.periodStart" class="flex flex-wrap">
                <span class="flex text-sm">
                  {{ item.data.periodStart }}
                </span>
              </div>
              <div v-else-if="item.status">
                <Badge
                  :label="item.data.status"
                  variant="primary"
                  :background-color="
                    item.data.status === 'active'
                      ? `rgba(19, 181, 106, 0.15)`
                      : `rgba(135, 142, 153, 0.15)`
                  "
                  :color="item.data.status === 'active' ? '#13B56A' : '#333333'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    height: 35px;
                    border-radius: 5px;
                  "
                />
              </div>
              <div v-else-if="item.id">
                <div
                  class="flex pb-3 mb-1 px-3 more h-8 cursor-pointer"
                  @click="
                    $router.push({
                      name: 'FunctionalModerationDetails',
                      params: { year: item.data.year, goalId: item.data.id },
                    })
                  "
                >
                  <icon
                    icon-name="icon-eye"
                    size="xsm"
                    class="-mt-1 -ml-2 text-blueCrayola"
                    style="padding: 3px 10px; width: 36px; height: 36px"
                  />
                </div>
              </div>
            </template>
          </Table>

        <div
          class="flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"
          v-else
        >
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <p class="font-normal text-base leading-5 text-jet text-center w-96">
            Moderation Data not available.
          </p>
        </div>

        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Loader from "@scelloo/cloudenly-ui/src/components/loader";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Icon from "@/components/Icon";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  name: "PerformanceModeration",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Breadcrumb,
    Icon,
    Badge,
    Table,
    Card,
    Loader,
    CardFooter,
  },
  data() {
    return {
      isLoading: true,
      agreements: "",
      moderations: [],
      cycleHeaders: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Moderation",
          href: "moderation",
          id: "PerformanceModeration",
        },
      ],
      headers: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      items: [],
      appraisalsArr: [],
      reportingTo: false,
    };
  },
  methods: {
    percentage(value, total) {
      return Math.round((value / total) * 100);
    },

    getOrganizationGoal() {
      const agreement = [];
      this.$_getOrganizationGoal('').then((response) => {
        const goalData = response.data.goals;

        if (goalData.length > 0) {
          goalData.forEach((goals) => {
            agreement.push({
              id: goals.goal.goal_performance_templates.id,
              year: goals.goal.year,
              description:
                goals.goal.goal_performance_templates.agreement_title,
              status: goals.goal.status,
            });

            agreement.push(
              this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
            );

            this.items.push(
              agreement.reduce((r, i) => Object.assign(r, i), {})
            );

            return {};
          });
        }
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const apprArray = [];

        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;

          const endDate = `${appraisalCycles.cycle_end_date}`;

          apprArray.push({
            periodStart: `${this.formatDateTime(startDate)} - ${this.formatDateTime(
              endDate
            )}`,
          });

          appraisalCycles.cycles.map((cycle) => {
            this.headers.push({
              title: cycle.name,
              value: cycle.name,
            });

            apprArray.push({
              [cycle.name]: `${this.formatDateTime(
                cycle.appraisal_starts
              )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
            });

            return {};
          });

          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );

          this.headers.push(
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );

          return {};
        });

        this.getOrganizationGoal();
      });
    },
  },

  mounted() {
    this.getAppraisalCycle();
  },
};
</script>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
